.checkbox {
  --c_checkbox_unchecked_border: var(--c_neutral-70);
  --c_checkbox_unchecked_background: var(--c_white);
  --c_checkbox_checked_border: var(--c_green);
  --c_checkbox_checked_background: var(--c_green);
  --c_checkbox_checked_forground: var(--c_white);
  --c_checkbox_unchecked_border_focus: var(--c_green);

  --c_checkbox_disabled: var(--c_neutral-85);

  /* 
    Ideally, the entire size of this button should be tied to the
    size of the checkbox. This could be tied to the width/height,
    or to the font-size. From a CSS perspective, font-size makes the
    most sense, but since this isn't 'text', that may not be most
    natural to a user. I'm open to changing it in the future.
  */
  font-size: 1rem;
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    height: 1em;
    width: 1em;
    border-radius: 0.25em;
    background: var(--c_checkbox_unchecked_background);
    border: 1px solid var(--c_checkbox_unchecked_border);
    pointer-events: none;
  }
  .check {
    display: block;
    height: 0.75em;
    width: 0.75em;
  }
  .check {
    color: var(--c_checkbox_checked_forground);
  }
  input:focus + button {
    border-color: var(--c_checkbox_unchecked_border_focus);
  }

  .check {
    visibility: hidden;
  }

  input:checked + button {
    background: var(--c_checkbox_checked_background);
    border-color: var(--c_checkbox_checked_border);

    .check {
      visibility: visible;
    }
  }

  input:disabled + button .check {
    color: var(--c_checkbox_disabled);
  }
}
