.sparkle {
  .star1, .star2, .star3 {
    animation: sparkle 1.2s ease-in-out infinite;
  }
  .star2 { animation-delay: 200ms; }
  .star3 { animation-delay: 400ms; }
}
@keyframes sparkle {
  0% { opacity: 1; }
  40% { opacity: 0; }
  80% { opacity: 1; }
  100% { opacity: 1; }
}