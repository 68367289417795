.input {
  position: relative;

  input,
  textarea {
    color: var(--c_neutral-4);
    border-radius: 1.5rem;
    background: var(--c_yellow-98);
    border: 1px solid var(--c_neutral-86);
    line-height: 1.25rem;
    padding: 1rem 1.5rem;

    &:active, &:focus {
      box-shadow: none;
      color: inherit;
      border: 1px solid var(--c_green-44);
      background: var(--c_yellow-98);
    }
  }

  input {
    height: 3rem;
  }

  label {
    font-weight: normal;
  }

  textarea {
    height: 7.5rem;
  }


  &.currency {
    position: relative;
    
    input {
      padding-left: 2.2rem;
    }

    &::before {
      color: var(--c_neutral-4);
      content: '$';
      position: absolute;
      bottom: .6875rem;
      left: 2.5rem;
    }
  }
}
