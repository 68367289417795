.button {
  text-align: center;
  border-radius: 6250rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.625rem;

  .iconWrapper {
    display: flex;
    align-items: center;

    &.left {
      position: absolute;
      left: 1rem;
    }
  }

  svg {
    display: inline;
    height: 1.5rem;
    width: 1.5rem;
  }

  &:global(.s) {
    height: 2.25rem;
    min-width: 2.25rem;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &:global(.m) {
    height: 2.5rem;
    min-width: 2.5rem;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &:global(.l) {
    height: 3rem;
    min-width: 3rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &:global(.xl) {
    height: 3.375rem;
    min-width: 3.375em;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  &:global(.primary) {
    color: var(--c_element-primaryWhite);
    background-color: var(--c_element-primaryBlack);
  }

  &:global(.significant) {
    color: var(--c_element-primaryWhite);
    background-color: var(--c_moss-700);
  }

  &:global(.secondary) {
    background-color: var(--c_neutrals-200);
  }

  &:global(.disabled) {
    background-color: var(--c_neutrals-100);
    color: var(--c_element-disabledBlack);
  }

  &:global(.ghost) {
    border: 1px solid var(--c_neutrals-300);
    background-color: transparent;
  }

  &:global(.negative) {
    background-color: var(--c_danger-100);
    color: var(--c_danger-700);
  }

  &:global(.inverted) {
    color: var(--c_element-primaryBlack);
    background-color: var(--c_element-primaryWhite);
  }

  &:global(.tertiary) {
    border: 1px solid transparent;
    background-color: transparent;
  }

  .submit,
  .reset {
    display: none;
  }
}