.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

.lightbox.blurredBackground {
  backdrop-filter: blur(4.5px);
}

.lightbox .base {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  /* Center the content on large displays */
  &:before {
    flex: 1 1 50%;
    content: '';
    visibility: hidden;
  }

  &:after {
    flex: 1 1 50%;
    content: '';
    visibility: hidden;
  }

  .positioner {
    flex: 0 0 auto;
    height: 100vh;

    .trap {
      height: 100vh; // Fallback
      height: 100dvh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.container {
  width: auto;
  height: auto;
  max-width: theme('screens.sm');
  border-radius: 3px;
  margin: 1rem auto;
  overflow: hidden;

  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.24);
}

.bottom {
  .trap {
    &:before {
      flex: 1 1 100%;
      content: '';
      visibility: hidden;
    }

    .container {
      flex: 1 0 auto;
    }
  }
}

.top {
  .trap {
    &:after {
      flex: 1 1 100%;
      content: '';
      visibility: hidden;
    }

    .container {
      flex: 1 0 auto;
    }
  }
}

@media (min-width: theme('screens.md')) {
  .container {
    max-width: theme('screens.md');
  }
}

@media (min-width: theme('screens.lg')) {
  .container {
    max-width: theme('screens.lg');
  }
}

@media (min-width: theme('screens.xl')) {
  .container {
    max-width: theme('screens.xl');
  }
}