.caseStudyViewer {
  position: relative;

  border-radius: 0;

  width: 100%;
  height: 100%;

  box-shadow: none;

  .psudoPhone {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
      background: white;
    }
  }

  .left,
  .right {
    display: none;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: 0;

    color: var(--c_element-primaryWhite);
    background: var(--c_neutrals-1000);

    border-radius: 4rem;
    transform: rotate(180deg);

    z-index: 1;

    cursor: pointer;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .right {
    transform: rotate(0deg);
    left: initial;
    right: 0;
  }
}

@media (min-width: theme('screens.sm')) {
  .caseStudyViewer {
    position: relative;

    width: auto;
    height: auto;

    box-shadow: none;

    padding: 0 3rem;

    filter: drop-shadow(0 0 34px rgba(0, 0, 0, 0.24));

    .psudoPhone {
      border-radius: 2rem;
      overflow: hidden;

      width: 375px;
      height: 750px;

      iframe {
        width: 100%;
        height: 100%;
        background: white;
      }
    }

    .left,
    .right {
      display: block;
    }
  }
}