a, a:hover, a:visited, a:active, span, button {
  &.fancyButton {
    display: inline-block;

    // TODO: move all colors to vars, or to tailwind if we're keeping it.
    color: var(--c_green-44);
    font-size: 1.125em;
    letter-spacing: -0.02rem;
    line-height: normal;
    padding: 1rem 1.5rem;
    // TODO: move all colors to vars, or to tailwind if we're keeping it.
    border: 2px solid var(--c_green-44);
    border-radius: 2rem;
    background: white;

    cursor: pointer;

    text-align: center;

    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:disabled {
      cursor: default;
      &:not(.loading) {
        color: #d8d8d8;
        border-color: #d8d8d8;
      }
    }

    .container {
      display: flex;
      align-items: center;

      .loadingIcon {
        flex: 0 0 auto;
        opacity: 0;
        height: 1.3em;
        width: 0;
        animation: slideOpen 100ms ease-out forwards;
        margin-right: 0.5em;
      }

      .content {
        flex: 1 1 auto;
      }
    }
  }
}

@keyframes slideOpen {
  0% { width: 0; }
  100% { width: 1.3em; opacity: 1; }
}
