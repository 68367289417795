.wrapper {
  flex-grow: 1;

  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 0 1rem;
  margin: 0 auto;

  width: 100%;
}

@media (min-width: theme('screens.sm')) {
  .wrapper {
    padding: 0 1.5rem;
    max-width: 640px;
  }
}
@media (min-width: theme('screens.md')) {
  .wrapper {
    max-width: 768px;
  }
}

@media (min-width: theme('screens.lg')) {
  .wrapper {
    padding: 0 2rem;
    max-width: 1024px;
  }
}

@media (min-width: theme('screens.xl')) {
  .wrapper {
    max-width: 1280px;
  }
}
