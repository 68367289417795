.wistiaVideo {
  position: relative;

  margin: 1.5rem 0;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .reponsiveWrapper {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    .embed {
      position: relative;
      height: 100%;
      width: 100%;

      .swatch {
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: opacity 200ms;
        width: 100%;

        img {
          filter: blur(5px);
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
}
