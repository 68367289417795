.defaultAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  background: theme('colors.lapis-fine');
  color: theme('colors.moss');
  overflow: hidden;

  .background {
    position: absolute;
    max-width: none;
    opacity: 0.11;
  }

  .icon {
    width: 2rem;
    opacity: 0.64;
  }
  
  &::after {
    display: block;
    content: "";
    /* 8:5 aspect ratio */
    padding-bottom: 62.5%;
  }
}
