.markdown {
  p + p {
    margin-top: 1em;
  }

  p, ol, ul, blockquote, table, h1, h2, h3, h4, h5, h6 {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  ul {
    list-style: disc;
    margin-left: 2rem;
  }
  ol {
    list-style: decimal;
    margin-left: 2rem;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  a {
    text-decoration: underline;
  }
}
