.imageWithBackup {
  max-width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  img, object {
    max-width: 100%;
    height: auto;
  }
}