.wrapper {
  .inputWrapper {
    position: relative;
  }

  .input {
    width: 100%;
    display: block;
    -webkit-appearance: none;
    appearance: none;
    border-color: var(--c_neutrals-400);

    &:focus-visible {
      border-color: var(--c_element-primaryBlack);
      outline: none;
    }
  }

  label {
    transition: all ease-out 0.2s;
    position: absolute;
    font-size: 0.75rem;
    line-height: 0.25rem;
    opacity: 0;
    padding: 0 0.25rem;
    top: 1.5rem;
    left: 0.5rem;
    transform: scale(135%);
    transform-origin: top left;
    pointer-events: none;
  }

  .accessory {
    position: absolute;
    right: 0.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
  }

  .input:not(:placeholder-shown) + label,
  select.input + label {
    opacity: 1;
    transform: scale(100%);
  }

  ::placeholder {
    color: var(--c_element-disabledBlack);
  }

  select:invalid {
    color: var(--c_element-disabledBlack);
  }

  .hint {
    margin-top: 0.25rem;
  }

  &:global(.alert) {
    .input {
      border-color: var(--c_danger-700);
      background: var(--c_danger-100);
    }

    .hint {
      color: var(--c_danger-700);
    }

    label {
      background: var(--c_danger-100);
    }
  }

  &:global(.positive) {
    .input {
      border-color: var(--c_success-700);
      background: var(--c_success-100);
    }

    .hint {
      color: var(--c_success-700);
    }

    label {
      background: var(--c_success-100);
    }
  }

  &:global(.disabled) {
    color: var(--c_element-disabledBlack);

    &:global(.bounded) {
      .input {
        border-color: var(--c_neutrals-400);
        background-color: var(--c_neutrals-100);
      }
    }

    &:global(.boundless) {
      .input {
        border-color: var(--neutrals-200);
      }
    }
  }

  &:global(.bounded) {
    .input {
      border-width: 1px;
      border-radius: 0.5rem;
      background-color: var(--c_white);
      padding: 1rem 0.75rem;
    }

    &:global(.tight) {
      .input {
        padding: 0.5rem 0.75rem;
      }
    }

    .input:not(:placeholder-shown) + label,
    select.input + label {
      top: -0.075rem;
      left: 0.5rem;
    }

    label {
      background-color: var(--c_white);
    }

    .hint {
      margin-left: 0.75rem;
    }
  }

  &:global(.boundless) {
    .input {
      border-bottom-width: 1px;
      background-color: transparent;
      padding: 1rem 0 0.5rem;
    }

    &:global(.tight) {
      .input {
        padding: 0.75rem 0 0.25rem;
      }
    }

    &.ta {
      .inputWrapper {
        padding: 1rem 0 0.5rem;
      }
      .input {
        padding: 0;
      }
    }

    .input:not(:placeholder-shown) + label,
    select.input + label {
      top: 0;
      left: -0.25rem;
    }

    label {
      background-color: transparent;
      color: var(--c_element-disabledBlack);
    }

    .accessory {
      top: calc(50% + 0.5rem);
    }
  }

  &:global(.xl) {
    .input {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  &:global(.l) {
    .input {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  &.showRequired label::after {
    content: '*';
    color: var(--c_danger-600);
    margin-left: 0.25rem;
  }
}
