.listWrapper {
  .listItem {
    .details {
      flex-grow: 1;
      flex-direction: column;
      min-height: 2.25rem;
      display: flex;
      justify-content: center;
      overflow-x: clip;
  
      .description {
        margin-top: 0.125rem;
      }
    }
  
    .iconWrapper {
      > * {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0.5rem
      }
    }
  
    .icon {
      height: 2.5rem;
      width: 2.5rem;
      object-fit: cover;
      border-radius: .5rem;
    }
  
  
    .check {
      button {
        border: 2px solid var(--c_neutralsCharcoal);
        border-radius: 0.125rem;
      }
      input:checked + button {
        border-color:  var(--c_moss-700);
        background: var(--c_moss-700);
      }
    }
  }
}
