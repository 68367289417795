.text {
  &:global(.regular) {
    font-weight: 600;
  }

  &:global(.heavy) {
    font-weight: 700;
  }

  &:global(.light) {
    font-weight: 400
  }

  &:global(.primaryWhite) {
    color: var(--c_element-primaryWhite);
  }

  &:global(.primaryBlack) {
    color: var(--c_element-primaryBlack);
  }

  &:global(.secondaryBlack) {
    color: var(--c_element-secondaryBlack);
  }

  &:global(.tertiaryBlack) {
    color: var(--c_element-tertiaryBlack)
  }

  &:global(.secondary) {
    color: var(--c_secondary-700);
  }

  &:global(.disabledBlack) {
    color: var(--c_element-disabledBlack);
  }

  a {
    color: var(--c_element-primaryBlack);
    text-decoration: underline;
  }
}

h1.headline {
  letter-spacing: -0.03125rem;

  &:global(.xs) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &:global(.s) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  &:global(.m) {
    font-size: 1.75rem;
    line-height: 2rem;
  }

  &:global(.l) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  &:global(.xl) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

p.paragraph {
  &:global(.xs) {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  &:global(.s) {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }

  &:global(.m) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &:global(.l) {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &:global(.xl) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}