.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}

.spinner-icon-white {
  display: block;
  background: transparent url('~images/icons/spinner-white.svg') no-repeat center center;
  background-size: contain !important;

  &--sm {
    width: 16px;
    height: 16px;
  }

  &--md {
    width: 24px;
    height: 24px;
  }

  &--lg {
    width: 36px;
    height: 36px;
  }

  &--xl {
    width: 72px;
    height: 72px;
  }
}

.spinner-icon {
  display: block;
  background: transparent url('~images/icons/spinner.svg') no-repeat center center;
  background-size: contain !important;

  &--sm {
    width: 16px;
    height: 16px;
  }

  &--md {
    width: 24px;
    height: 24px;
  }

  &--lg {
    width: 36px;
    height: 36px;
  }

  &--xl {
    width: 72px;
    height: 72px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
