.modal {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;

  header,
  section,
  footer {
    padding: 1rem;
    background-color: white;
  }

  header {
    position: relative;

    color: var(--c_text);

    h2 {
      font-family: var(--f_open-sans);
      font-size: 1.5rem;
      font-weight: bold;
      text-align: left;
      margin-right: 2rem; // space for close button
    }
    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
    }
  }
  section {
    padding-top: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
  .withTopShadow {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    position: sticky;
    bottom: 0;

    background: var(--c_white);

    .button {
      font-size: 1rem;
      background: var(--c_green);
      color: var(--c_white);
      padding: 0.5em 0.75em;
      margin-left: 6px;
      margin-right: 6px;

      min-width: 7em;

      &.secondary {
        background: var(--c_neutral-95);
        border-color: var(--c_neutral-95);
        color: var(--c_black);
        font-weight: 500;
      }
      &:disabled {
        background: var(--c_neutral-95);
        border-color: var(--c_neutral-95);
        color: var(--c_neutral-85);
      }
    }
  }
}

@media (min-width: theme('screens.sm')) {
  .modal {
    max-width: 33rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    header,
    section {
      padding: 1.5rem;
    }

    section {
      padding-top: 0;
    }
    footer {
      padding: 1rem 1.5rem;

      .button {
        font-size: 1.125rem;
        padding: 0.5em 1.25em;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
